var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.merchantList.after
        ? _c(
            "div",
            { staticClass: "form-box mb-15" },
            [
              _c(
                "div",
                { staticClass: "mb-10", staticStyle: { "font-weight": "500" } },
                [
                  _vm._v(
                    " 售后单号：" + _vm._s(_vm.merchantList.after.as_no) + " "
                  ),
                ]
              ),
              _c(
                "el-row",
                { staticClass: "mb-10", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm.merchantList.after && _vm.merchantList.after.member
                      ? _c("div", [
                          _vm._v(
                            " 客户姓名：" +
                              _vm._s(_vm.merchantList.after.member.fullname) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm.merchantList.after && _vm.merchantList.after.member
                      ? _c("div", [
                          _vm._v(
                            " 客户电话：" +
                              _vm._s(_vm.merchantList.after.member.mobile) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "mb-10", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm.merchantList.after &&
                    _vm.merchantList.after.order.store_delivery &&
                    _vm.merchantList.after.order.store_delivery.logistics
                      ? _c("div", [
                          _vm._v(
                            " 城市仓：" +
                              _vm._s(
                                _vm.merchantList.after.order.store_delivery
                                  .logistics.name
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm.merchantList.after &&
                    _vm.merchantList.after.order.store_delivery
                      ? _c("div", [
                          _vm._v(
                            " 自提点信息：" +
                              _vm._s(
                                _vm.merchantList.after.order.store_delivery.name
                              )
                          ),
                          _vm.merchantList.after.order.store_delivery.address
                            ? _c("span", [
                                _vm._v(
                                  " ，" +
                                    _vm._s(
                                      _vm.merchantList.after.order
                                        .store_delivery.address
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm.merchantList.after.order.store_delivery.mobile
                            ? _c("span", [
                                _vm._v(
                                  "，" +
                                    _vm._s(
                                      _vm.merchantList.after.order
                                        .store_delivery.mobile
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("div", { staticStyle: { "font-weight": "500" } }, [
                _vm._v(
                  " 订单号：" + _vm._s(_vm.merchantList.after.order_no) + " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "auto-table-flex mb-30" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "goodsTable",
              attrs: {
                data: _vm.goodsData,
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "goodsName",
                  width: "160",
                  align: "center",
                  label: "主图",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "kv pointer",
                          attrs: {
                            src: scope.row.product.kv,
                            "preview-src-list": [
                              scope.row.product.kv,
                              ...scope.row.product.srp_pic.split(","),
                            ],
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "font",
                            staticStyle: { color: "#13ae67" },
                          },
                          [_vm._v("点击查看详细图片")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  align: "center",
                  label: "商品信息",
                  width: "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product
                          ? _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column info",
                                staticStyle: { "align-items": "flex-start" },
                              },
                              [
                                _c("div", { staticClass: "pb" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$empty.empty(
                                          scope.row.product.title
                                        )
                                      ) +
                                      " (" +
                                      _vm._s(
                                        _vm.$empty.empty(
                                          scope.row.product.sku_code
                                        )
                                      ) +
                                      ") "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "py",
                                    staticStyle: { color: "#f93a16" },
                                  },
                                  [
                                    _vm._v(
                                      " 开市价：" +
                                        _vm._s(
                                          _vm.tool.toDecimal2(
                                            (scope.row.product.price * 10000) /
                                              100 /
                                              10000
                                          )
                                        ) +
                                        "元 "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "py" }, [
                                  _vm._v(
                                    " 毛重/毛单价：" +
                                      _vm._s(
                                        _vm.$empty.empty(
                                          scope.row.order_detail.gross_weight
                                        )
                                      ) +
                                      "斤【" +
                                      _vm._s(
                                        _vm.tool.toDecimal2(
                                          (scope.row.order_detail
                                            .gross_weight_price *
                                            10000) /
                                            100 /
                                            10000
                                        )
                                      ) +
                                      "元/斤】 "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "py d-flex a-center" },
                                  [
                                    _vm._v(
                                      " 净重/净单价：" +
                                        _vm._s(
                                          _vm.$empty.empty(
                                            scope.row.order_detail.weight
                                          )
                                        ) +
                                        "斤【" +
                                        _vm._s(
                                          _vm.tool.toDecimal2(
                                            (scope.row.order_detail
                                              .weight_price *
                                              10000) /
                                              100 /
                                              10000
                                          )
                                        ) +
                                        "元/斤】 "
                                    ),
                                    scope?.row?.order_detail?.weight_diff
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "14px",
                                            height: "14px",
                                          },
                                          attrs: {
                                            alt: "",
                                            src: require("@/assets/image/tip.png"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onHandleWeight(
                                                scope.row.order_detail
                                                  .weight_diff
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                scope.row.product &&
                                scope.row.product.supplier &&
                                scope.row.product.buyer
                                  ? _c("div", { staticClass: "pt" }, [
                                      _vm._v(
                                        " 主供货商：" +
                                          _vm._s(
                                            scope.row.product.supplier.title
                                          ) +
                                          "(" +
                                          _vm._s(
                                            scope.row.product.buyer.fullname
                                          ) +
                                          ") "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _c("div", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "id_card", align: "center", label: "采购数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(scope.row.order_detail.qty)
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "id_card",
                  align: "center",
                  label: "采购总净重",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    (scope.row.order_detail.weight *
                                      10000 *
                                      scope.row.order_detail.qty) /
                                      10000
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "freight_amt",
                  align: "center",
                  label: "商品总额",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.tool.toDecimal2(
                                    (scope.row.order_detail.price *
                                      10000 *
                                      scope.row.order_detail.qty) /
                                      10000 /
                                      100
                                  )
                                ) + "元"
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "purchase_amt",
                  align: "center",
                  label: "服务费",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.order_detail.purchase_amt * 10000) /
                                  100 /
                                  10000
                              )
                            ) + "元"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "freight_amt", align: "center", label: "运费" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.order_detail.freight_amt * 10000) /
                                  100 /
                                  10000
                              )
                            ) + "元"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "finance_amt",
                  align: "center",
                  label: "金融手续费",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.tool.toDecimal2(
                                (scope.row.order_detail.finance_amt * 10000) /
                                  100 /
                                  10000
                              )
                            ) + "元"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "finance_amt",
                  align: "center",
                  label: "总金额",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      scope.row.order_detail.finance_amt *
                                        10000 +
                                        scope.row.order_detail.purchase_amt *
                                          10000 +
                                        scope.row.order_detail.freight_amt *
                                          10000 +
                                        scope.row.order_detail.price *
                                          10000 *
                                          scope.row.order_detail.qty
                                        ? (scope.row.order_detail.finance_amt *
                                            10000 +
                                            scope.row.order_detail
                                              .purchase_amt *
                                              10000 +
                                            scope.row.order_detail.freight_amt *
                                              10000 +
                                            scope.row.order_detail.price *
                                              10000 *
                                              scope.row.order_detail.qty) /
                                            10000 /
                                            100
                                        : 0
                                    )
                                  ) +
                                  "元"
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reason",
                  align: "center",
                  label: "供货单号",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail &&
                        scope.row.order_detail.supplier_order
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.order_detail.supplier_order
                                      .supplier_order_no
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "reason", align: "center", label: "实际供货商" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product && scope.row.product.supplier
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.product.supplier.title
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "reason", align: "center", label: "采购员" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product && scope.row.product.buyer
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.product.buyer.fullname
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "reason", align: "center", label: "品控缺货" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail &&
                        scope.row.order_detail.order_detail_delivery
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.order_detail.order_detail_delivery
                                      .stockout_number
                                  )
                                )
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "reason", align: "center", label: "缺货毛重" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.order_detail &&
                        scope.row.order_detail.order_detail_delivery
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$empty.empty(
                                    (scope.row.order_detail
                                      .order_detail_delivery.stockout_number *
                                      1000 *
                                      scope.row.order_detail.gross_weight *
                                      1000) /
                                      1000000
                                  )
                                ) + "斤"
                              ),
                            ])
                          : _c("span", [_vm._v("—")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.merchantList.after && _vm.merchantList.after.self_product_name
        ? _c(
            "div",
            { staticStyle: { "margin-bottom": "20px", color: "#f93a16" } },
            [
              _vm._v(
                " 该用户近 7 日有自采：" +
                  _vm._s(_vm.merchantList.after.self_product_name) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.afterLoading,
              expression: "afterLoading",
            },
          ],
          ref: "afterTable",
          attrs: {
            data: _vm.afterTableData,
            border: true,
            "header-cell-style": {
              color: "#333333",
              background: "#EFF6FF",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "goodsName", align: "center", label: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == _vm.AFTER_SHOW_TYPE_ENUM["1"].value
                      ? _c("div", [
                          _vm._v(
                            " 用户：" +
                              _vm._s(scope.row.member.fullname) +
                              " (" +
                              _vm._s(scope.row.member.mobile) +
                              ") "
                          ),
                        ])
                      : _vm._e(),
                    scope.row.type == _vm.AFTER_SHOW_TYPE_ENUM["2"].value
                      ? _c("div", [
                          _vm._v(
                            " 供应商：" + _vm._s(scope.row.supplier.title) + " "
                          ),
                        ])
                      : _vm._e(),
                    scope.row.type == _vm.AFTER_SHOW_TYPE_ENUM["3"].value
                      ? _c("div", [
                          _vm._v(" 货号：" + _vm._s(scope.row.sku_code) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "percent_15",
              align: "center",
              label: "15天售后率",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.percent_15
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s((scope.row.percent_15 * 100).toFixed(2)) +
                              "% "
                          ),
                        ])
                      : _c("div", [_vm._v("—")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "percent_30", align: "center", label: "30天售后率" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.percent_30
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s((scope.row.percent_30 * 100).toFixed(2)) +
                              "% "
                          ),
                        ])
                      : _c("div", [_vm._v("—")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "total_aftersale_amt",
              align: "center",
              label: "总售后退款金额",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tool.toDecimal2(
                            (scope.row.total_aftersale_amt * 10000) /
                              100 /
                              10000
                          )
                        ) + "元"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "30px" } }),
      _vm.merchantList.aftersale_detail
        ? _c(
            "div",
            {
              staticClass: "pb-2 mb-15",
              staticStyle: { "border-bottom": "1px solid #eeeeee" },
            },
            [
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 商品标签：" +
                    _vm._s(
                      _vm.merchantList?.aftersale_detail?.product
                        ?.promotion_tag_name || "—"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _c("span", { staticStyle: { "margin-right": "100px" } }, [
                  _vm._v(
                    " 等级：" +
                      _vm._s(
                        _vm.merchantList?.aftersale_detail?.product?.grade ||
                          "—"
                      )
                  ),
                ]),
                _c("span", { staticStyle: { "margin-right": "100px" } }, [
                  _vm._v(
                    " 最小甜度：" +
                      _vm._s(
                        _vm.merchantList?.aftersale_detail?.product?.min_se ||
                          "—"
                      )
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " 最大甜度：" +
                      _vm._s(
                        _vm.merchantList?.aftersale_detail?.product?.max_se ||
                          "—"
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 规格：" +
                    _vm._s(
                      _vm.merchantList?.aftersale_detail?.product?.spec || "—"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 不良率：" +
                    _vm._s(
                      _vm.merchantList.aftersale_detail.product.reject
                        ? _vm.merchantList.aftersale_detail.product.reject + "%"
                        : "—"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 免赔情况：" +
                    _vm._s(
                      _vm.merchantList.aftersale_detail.product.ded
                        ? _vm.merchantList.aftersale_detail.product.ded
                        : "—"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 可申请售后条件：" +
                    _vm._s(
                      _vm.merchantList.aftersale_detail.product.as_cond
                        ? _vm.merchantList.aftersale_detail.product.as_cond
                        : "—"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 简介：" +
                    _vm._s(
                      _vm.merchantList.aftersale_detail.product.intro
                        ? _vm.merchantList.aftersale_detail.product.intro
                        : "—"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 售后原因：" +
                    _vm._s(
                      _vm.aftertype[
                        _vm.merchantList.aftersale_detail.reason_type
                      ]
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-10" }, [
                _vm._v(
                  " 报损重量：" +
                    _vm._s(_vm.merchantList.aftersale_detail.product_weight) +
                    "斤 "
                ),
              ]),
              _c("div", { staticClass: "d-flex mb-10" }, [
                _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("问题描述："),
                ]),
                _c("div", [
                  _vm._v(_vm._s(_vm.merchantList.aftersale_detail.desc)),
                ]),
              ]),
              _c("div", { staticClass: "d-flex mb-10" }, [
                _c("div", [_vm._v("商品包装图：")]),
                _vm.merchantList?.aftersale_detail?.product?.pack_pic
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.merchantList?.aftersale_detail?.product?.pack_pic.split(
                          ","
                        ),
                        function (img, idx) {
                          return _c("el-image", {
                            key: idx,
                            staticClass: "after-image",
                            attrs: {
                              src: img,
                              "preview-src-list":
                                _vm.merchantList?.aftersale_detail?.product?.pack_pic.split(
                                  ","
                                ),
                            },
                          })
                        }
                      ),
                      1
                    )
                  : _c("div", [_vm._v("—")]),
              ]),
              _c("div", { staticClass: "d-flex mb-10" }, [
                _c("div", [_vm._v("商品介绍视频：")]),
                _vm.merchantList?.aftersale_detail?.product?.srp_video
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.merchantList?.aftersale_detail?.product?.srp_video.split(
                          ","
                        ),
                        function (video, idx) {
                          return _c("video", {
                            key: idx,
                            ref: "videoPlayer",
                            refInFor: true,
                            staticClass: "video pointer",
                            attrs: {
                              src: video,
                              autoplay: false,
                              controls: true,
                            },
                          })
                        }
                      ),
                      0
                    )
                  : _c("div", [_vm._v("—")]),
              ]),
              _vm.merchantList?.aftersale_detail?.order_detail?.supplier_order
                .audit_status == _vm.REVIEW_STATUS_ENUM["notYetPassed"].value
                ? _c(
                    "div",
                    {
                      staticClass: "mb-10",
                      staticStyle: { color: "#f93a16", "font-size": "20px" },
                    },
                    [
                      _vm._v(
                        " 品控状态:" +
                          _vm._s(
                            Object.values(_vm.REVIEW_STATUS_ENUM).find(
                              (item) =>
                                item.value ==
                                _vm.merchantList?.aftersale_detail?.order_detail
                                  ?.supplier_order.audit_status
                            ).label
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              Number(
                _vm.merchantList?.aftersale_detail?.order_detail?.supplier_order
                  .partial_quality_control_quantity
              ) > 0 &&
              Number(
                _vm.merchantList?.aftersale_detail?.order_detail?.supplier_order
                  .partial_quality_control_quantity
              ) <
                Number(
                  _vm.merchantList?.aftersale_detail?.order_detail
                    ?.supplier_order.product_number
                )
                ? _c(
                    "div",
                    {
                      staticClass: "mb-10",
                      staticStyle: { color: "#f93a16", "font-size": "20px" },
                    },
                    [
                      _vm._v(
                        " 供应商未完全品控：供货单数量：" +
                          _vm._s(
                            _vm.merchantList?.aftersale_detail?.order_detail
                              ?.supplier_order.product_number
                          ) +
                          "，品控：" +
                          _vm._s(
                            _vm.merchantList?.aftersale_detail?.order_detail
                              ?.supplier_order.partial_quality_control_quantity
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mb-15",
          staticStyle: { "border-bottom": "1px solid #eeeeee" },
        },
        [
          _c(
            "el-steps",
            { attrs: { direction: "vertical" } },
            [
              _vm._l(_vm.step, function (item, index) {
                return [
                  _c("el-step", { attrs: { title: item.step_name } }, [
                    _c("div", { attrs: { slot: "icon" }, slot: "icon" }, [
                      _c("img", {
                        staticClass: "activeimg",
                        attrs: {
                          src: require("@/assets/image/active.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mb-50",
                        attrs: { slot: "description" },
                        slot: "description",
                      },
                      [
                        item.aftersale_step_history &&
                        item.aftersale_step_history.params
                          ? [
                              item.aftersale_step_history.params["mobile"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 联系电话：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "mobile"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["reason"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 售后(报损)原因：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "reason"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["weight"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 售后(报损)重量：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "weight"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["status"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 处理意见：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "status"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["amount"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 退款金额：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "amount"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["reason_title"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 原因选择：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "reason_title"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params[
                                "reason_content"
                              ]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "reason_content"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["remark"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 判定说明：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "remark"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params[
                                "intervene_remark"
                              ]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb",
                                      staticStyle: { color: "#777777" },
                                    },
                                    [
                                      _vm._v(
                                        " 介入原因：" +
                                          _vm._s(
                                            item.aftersale_step_history.params[
                                              "intervene_remark"
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["attach_pic"]
                                ? _c(
                                    "div",
                                    { staticClass: "mb d-flex" },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "#777777" } },
                                        [_vm._v("图片证据：")]
                                      ),
                                      _vm._l(
                                        item.aftersale_step_history.params[
                                          "attach_pic"
                                        ].split(","),
                                        function (img, idx) {
                                          return _c("el-image", {
                                            key: idx,
                                            staticClass: "mr-1 after-image",
                                            attrs: {
                                              "preview-src-list":
                                                item.aftersale_step_history.params[
                                                  "attach_pic"
                                                ].split(","),
                                              src: img,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              item.aftersale_step_history.params["attach_video"]
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex a-center mb" },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "#777777" } },
                                        [_vm._v("视频证据：")]
                                      ),
                                      _vm._l(
                                        item.aftersale_step_history.params[
                                          "attach_video"
                                        ].split(","),
                                        function (video, idxs) {
                                          return _c("video", {
                                            key: idxs,
                                            staticClass: "video pointer",
                                            attrs: {
                                              id: "myVideo",
                                              src: video,
                                              autoplay: false,
                                              controls: true,
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _c("div", { staticStyle: { color: "#999999" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.handle_type == 40
                                  ? item.create_time
                                  : item.update_time
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                  index == _vm.step.length - 1
                    ? _c("el-step", { attrs: { title: "" } }, [
                        _c("div", { attrs: { slot: "icon" }, slot: "icon" }),
                      ])
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("fix-weight-log", { ref: "refWeightLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }